/*===== SIDEBAR NAVIGATION =====*/
.sidebar .nav>li>a:after {
  content: '';
  clear: both;
  display: table;
}

.left-sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  transform: translateX(-250px);
  background-color: $black-russian;
  top:0;
  bottom: 0;
  @include media-breakpoint-up(md){
    transform: translateX(0);
    width: 250px;
    position: relative;
    z-index: 1;
  }

  background-attachment: fixed;
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  &::before {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($black-russian, .85);
    content: '';
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  z-index: 1050;
  min-height: 100vh;
  position: relative;
  .sidebar-inner {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin-top: 1rem;
    >li {
      position: relative;
      >a {
        padding: 1rem 1.5rem;
        line-height: 20px;
        color: $white-light;
        display: block;
        text-decoration: none;
        text-transform: capitalize;
        font-size: 1rem;
        font-weight: 400;
        white-space: nowrap;
      }
    }
  }
}

.sidebar {
  .sidebar-inner {
    > li {
      &.active > a {
        position: relative;
        z-index: 10;
        color: $white;
        background-color: rgba($primary, 0.4);
      }
    }
    .section-title {
      padding: 1.25rem 1.56rem 1rem;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 0.75rem;
      color: rgba($white-light, .7);
      letter-spacing: 1.2px;
      white-space: nowrap;
    }
    .sub-menu {
      .section-title {
        padding: 1.25rem .94rem 0.625rem;
      }
    }
  }
}


/* App brand */
.app-brand {
  position: relative;
  display: block;
  border-bottom: 1px solid rgba($border-color, .25);
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 76px;
    line-height: 76px;
    width: 250px;

    @include transition(width 0.3s ease-in-out);
    padding-left: 1.56rem;
    @include media-breakpoint-up(md){
      overflow: hidden;
    }
    img {
      max-width: 128px;
      vertical-align: middle;
    }
  }
  .brand-name {
    color: $white;
    font-size: 1.25rem;
    margin-left: .31rem;
  }
}

/*  Nav menus Icon Styles */
.sidebar .nav>li>a i {
  float: left;
  margin-right: 0.94rem;
  width: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 1.5rem;
}


/* Caret styles */
.sidebar li>a .caret {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 1.25rem;
  border: none;

  &:before {
    content: '\f142';
    display: block;
    text-align: center;
    transition: all .2s linear;
    opacity: .5;
  }
}

.sidebar li>a .caret:before {
  font-family: $font-family-icon !important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}

.sidebar li.active>a .caret:before,
.sidebar li.expand>a .caret:before,
.sidebar li.expanding>a .caret:before {
  transform: rotate(0);
}

.sidebar li.active.expand>a .caret:before,
.sidebar li.expand>a .caret:before,
.sidebar li.expanding>a .caret:before {
  transform: rotate(90deg);
}

.sidebar li.active>a .caret:before{
  opacity: 1;
}

.sidebar li.closed>a .caret:before,
.sidebar li.closing>a .caret:before {
  transform: rotate(0);
}

/*  sub-menu dropdowns */
.sidebar .sub-menu {
  list-style-type: none;
  padding: .625rem 0 .625rem 2.81rem;
  margin: 0;
  position: relative;
  >.active {
    .sidenav-item-link {
      color: $white;
    }
  }
  .has-sub.active {
    .sidenav-item-link {
      color: $white;
    }
  }
  .sub-menu {
    .active {
      a {
        color: $white;
        &::after {
          border-color: $white;
        }
      }
    }
  }
  >li>a {
    padding: 0.625rem 1.875rem 0.625rem 0.94rem;
    display: block;
    text-decoration: none;
    position: relative;
    font-size: 0.9375rem;
    white-space: nowrap;
    color: $white-light;
  }

  > li .sub-menu {
    padding-left: 2.18rem;

    >li>a {
      position: relative;
      padding-left: 0;
      &::after {
        position: absolute;
        content: '';
        top: 20px;
        left: -20px;
        border: 1px solid $white-light;
        width: 10px;
      }
    }
  }
}
.sidebar .has-sub.active.expand>.sub-menu {
  display: none;
}
.sidebar .has-sub  .has-sub .caret {
  top: 12px;
}

.sidebar {
  .sidenav-item-link {
    display: flex !important;
    flex-grow: 1;
    align-items: center;
    .nav-text {
      margin-right: auto;
      text-transform: capitalize;
    }
    .badge {
      padding: .4em .6em;
    }
  }
}

/* Sidebar for Mobile */
.sidebar-mobile-in {
  .left-sidebar {
    transform: translateX(0px) !important;
    z-index: 1050;
    transition: transform .3s ease-in-out;
    @include media-breakpoint-up(md){
      transform: translateX(-250px) !important;
    }
  }
}

.sidebar-mobile-out {
  .left-sidebar {
    transform: translateX(-250px) !important;
    z-index: 1050;
    @include media-breakpoint-up(md){
      transform: translateX(0px) !important;
    }
  }
}


/* Sidebar Minified */
.sidebar-minified .left-sidebar {
  width: 250px;
  z-index: 1050;
  transform: translateX(0);
  transition: transform .3s ease-in-out;
  @include media-breakpoint-up(md) {
    width: 85px;
    transition: width .3s ease-in;
  }
  .app-brand {
    .brand-name {
      @include media-breakpoint-up(md){
        visibility: hidden;
        opacity: 0;
        @include transition(opacity 0.3s ease-in-out);
      }
    }
  }
  .section-title {

    @include media-breakpoint-up(md){
      height: 0;
      white-space: nowrap;
      opacity: 0;
      visibility: hidden;
      padding: 0;
    }
  }
  .sidebar .sub-menu {
    padding-top: 0;
    padding-bottom: 0;
  }
  .nav>li>a>.caret,
  .nav>li>a>span {
    opacity: 1;

    @include media-breakpoint-up(md) {
      opacity: 0;
    }
  }
  .nav>li>a>.caret {
    right: 15px;
  }

  .nav li.has-sub .collapse>.sub-menu {
    left: 0;
    top: 0;
    width: 100%;
    margin: 0;
  }
  .nav li.has-sub.expand .collapse>.sub-menu {
    height: 0;
    li {
      visibility: hidden;
      opacity: 0;
    }
  }
  @include media-breakpoint-down(sm){
    .nav li.has-sub.expand .collapse>.sub-menu {
      @include transition(opacity 0.3s ease-in-out);
      display: block!important;
      opacity: 1;
      visibility: visible;
    }
  }
  .separator {
    border-top-width: 0;
  }
  .sidebar-footer {
    @include media-breakpoint-up(md){
      height: 0;
    }
  }
  .sidebar-footer-content {
    @include media-breakpoint-up(md){
      visibility: hidden;
      opacity: 0;
    }
  }
  .sidebar-scrollbar {
    overflow: unset !important;
  }
}


/* Sidebar Minified Hover */
.sidebar-minified .left-sidebar:hover {
  @include media-breakpoint-up(md) {
    width: 250px;
    margin-right: -10.3125rem;
  }
  .section-title {
    height: auto;
    padding: 1.25rem 1.56rem 1rem;
    visibility: visible;
    opacity: 1;
    @include transition(opacity 0.3s ease-in-out .1s);
  }
  .nav li.has-sub.expand .collapse>.sub-menu {
    @include transition(opacity 0.3s ease-in-out);
    height: min-content;

    li {
      opacity: 1;
      visibility: visible;
      @include transition(opacity .3s ease-out .25s);
    }
  }

  .nav>li>a>.caret,
  .nav>li>a>span {
    opacity: 1;
    @include transition(opacity 0.3s ease-in);
    transition-delay: 0.15s;
  }

  .brand-name {
    opacity: 1;
    visibility: visible;
    @include transition(opacity 0.3s ease);
    transition-delay: 0.3s;
  }
  .app-brand {
    .brand-name {
      @include media-breakpoint-up(md){
        opacity: 1;
        visibility: visible;
        transition-delay: 0.3s;
      }
    }
  }
  .sidebar .sub-menu {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }
  .separator {
    border-top-width: 1px;
  }
  .sidebar-footer {
    @include media-breakpoint-up(md){
      height: min-content;
    }
  }
  .sidebar-footer-content {
    visibility: visible;
    opacity: 1;
    @include transition(opacity .3s ease-in-out .15s);
  }
  .sidebar-scrollbar {
    overflow: hidden !important;
  }
}

/* Sidebar Minified Not transition */
.sidebar-minified:not(.left-sidebar) .left-sidebar {
  @include media-breakpoint-up(md){
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
    -webkit-transition-property: margin-left, margin-right, width;
    transition-property: margin-left, margin-right, width;
  }
}

/* Sidebar Static With Minified */
.sidebar-static {
  &.sidebar-minified {
    .page-wrapper {
      .main-header {
        @include transition(padding-left 0.3s ease-in-out);
      }
    }
    &.navbar-fixed {
      .page-wrapper .main-header {
        @include media-breakpoint-up(md){
          padding-left: 4.69rem;
        }
      }
    }
  }
  &.sidebar-minified-out {
    .left-sidebar {
      @include transition(width 0.3s ease-in-out);
    }
    .page-wrapper {
      .main-header {
        @include transition(padding-left 0.3s ease-in-out);
      }
    }
  }
}

/* Sidebar Fixed With Minified Layout Container */
.sidebar-fixed.sidebar-minified {
  @include media-breakpoint-up(md){
    .page-wrapper {
      padding-left: 5.25rem;
      @include transition(padding-left 0.3s ease-in-out);
    }
    &.navbar-fixed {
      .page-wrapper .main-header {
        padding-left: 4.69rem;
        @include transition(padding-left 0.3s ease-in-out);
      }
    }
  }
}

/* Sidebar Minified Out */
.sidebar-minified-out {
  .left-sidebar {
    z-index: 10000;
    transform: translateX(-250px);
    transition: transform .3s ease-in-out !important;
    @include media-breakpoint-up(md){
      transform: translateX(0px);
      transition: width 0.3s ease-in-out !important;
    }
  }
  .sidebar {
    .sub-menu {
      padding-bottom: .625rem;
      padding-top: .625rem;
    }
    .nav > li > a span {
      transition: opacity 0.3s ease-in-out 0.3s;
      visibility: visible;
      opacity: 1;
    }
    li > a .caret {
      transition: opacity 0.3s ease-in-out 0.3s;
      visibility: visible;
      opacity: 1;
    }
  }
  .nav li.has-sub.expand .collapse>.sub-menu {
    height: min-content;

    li {
      opacity: 1;
      visibility: visible;
      @include transition(opacity .3s ease-out .25s);
    }
  }

  .sidebar-footer-content {
    visibility: visible;
    opacity: 1;
    @include transition(opacity .3s ease-in-out .15s);
  }

  /* Sidebar Fixed With Sidebar Minified Out */
  .left-sidebar {
    @include transition(width 0.3s ease-in-out);
  }
  .page-wrapper {
    @include transition(padding-left 0.3s ease-in-out);
    .main-header {
      @include transition(padding-left 0.3s ease-in-out);
    }
  }
}


/* Sidebar Fixed And Offcanvas */
.sidebar-fixed-offcanvas,
.sidebar-fixed {
  .left-sidebar {
    @include media-breakpoint-up(md){
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1060;
    }
    .sidebar-with-footer {
      @include media-breakpoint-up(md){
        padding-bottom: 54px;
      }
      .sidebar-footer {
        @include media-breakpoint-up(md){
          position: absolute;
          bottom: 0;
        }
      }
    }

  }
  .page-wrapper {
    @include media-breakpoint-up(md){
      padding-left: 15.6rem;
    }
  }
  .main-header {
    @include media-breakpoint-up(md){
      padding-left: 15.6rem;
    }
  }
  &.navbar-static {
    .main-header {
      @include media-breakpoint-up(md){
        padding-left: 0;
      }
    }
  }
}

.sidebar-fixed-offcanvas {
  &.sidebar-collapse {
    .page-wrapper {
      padding-left: 0;
    }
  }
  &.sidebar-collapse-out {
    .sidebar-footer {
      @include media-breakpoint-up(md){
        left: 0px;
        @include transition(left 0.3s ease-in-out);
      }
    }
  }
}

/* Sidebar Collapse */
.sidebar-collapse {
  @include media-breakpoint-up(md) {
    .left-sidebar {
      margin-left: -15.6rem;
      @include transition(margin-left 0.3s ease-in-out);
    }
    .page-wrapper {
      @include transition(padding-left 0.3s ease-in-out);
    }
  }

}

/* Sidebar Out Collapse */
.sidebar-collapse-out {
  .left-sidebar {
    @include media-breakpoint-up(md){
      margin-left: 0px;
      @include transition(margin-left 0.3s ease-in-out);
    }

  }
  .page-wrapper {
    @include media-breakpoint-up(md){
      @include transition(padding-left 0.3s ease-in-out);
    }
  }
}


.sidebar-collapse.navbar-fixed {
  .page-wrapper {
    .main-header {
      padding-left: 0;
      @include transition(padding-left 0.3s ease-in-out);
    }
  }
}
.sidebar-collapse-out {
  .page-wrapper {
    .main-header {
      @include transition(padding-left 0.3s ease-in-out);
    }
  }
}

/* Sidebar Static With Footer */
.sidebar-static,
.sidebar-static-offcanvas {
  .sidebar.sidebar-with-footer {
    .slimScrollDiv {
      height: 100%;
      @include media-breakpoint-up(md){
        height: auto !important;
      }
    }
  }
  &.navbar-fixed {
    .main-header {
      @include media-breakpoint-up(md){
        padding-left: 15.6rem;
      }
    }
  }
}

/* Mobile Sticky Body Overlay */
.mobile-sticky-body-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out 0s;
  background-color: rgba(0,0,0,.5);
  z-index: 1045;
  @include media-breakpoint-up(md){
    display: none;
  }
}

/* Sidebar Footer Content*/
.sidebar-footer-content {
  width: 250px;
  background-color: $primary;
  ul {
      li {
        width: 50%;
        a {
          color: $white;
          text-align: center;
          display: block;
          padding-top: .5rem;
          padding-bottom: .5rem;

          i {
            font-size: 25px;
          }
        }
        &:first-child {
          border-right: 1px solid rgba($white, 0.2);
        }
      }
    }
}

/* Right Sidebar Toogler Add */
.right-sidebar-toggoler-in {
  .right-sidebar {
    right: 0;
    transition: right .3s ease-in-out;
    .btn-right-sidebar-toggler {
      right: 60px;
      transition: right .3s ease-in-out;
      i {
        transform: rotate(180deg);
        transition: transform .3s ease-in-out;
      }
    }
  }
}

.right-sidebar-toggoler-out {
  .right-sidebar {
    transition: right .3s ease-in-out;
    .btn-right-sidebar-toggler {
      transition: right .3s ease-in-out;
    }
    i {
        transform: rotate(0deg);
        transition: transform .3s ease-in-out;
      }
  }
}

/* Right Sidebar */
.right-sidebar {
  width: 60px;
  min-height: 100vh;
  position: fixed;
  right: -60px;
  top: 0;
  transition: right .3s ease-in-out;
  @include media-breakpoint-up(xl) {
    right: 0;
    transition: right .3s ease-in-out;
  }
  .btn-right-sidebar-toggler {
    position: fixed;
    top: 135px;
    right: 0;
    background-color: $white;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    width: 35px;
    height: 30px;
    border: 1px solid rgba($border-color, 1);
    i {
      line-height: 28px;
      margin-left: 5px;
      font-size: 1.25rem;
      display: inline-block;
    }
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  /* Form Group Label */
  .form-group label {
    line-height: 1.3;
  }

}


/* Nav Right Sidebar */
.nav-right-sidebar {
  flex-direction: column;
  position: relative;

  .nav-item {
    margin: auto;
    margin-bottom: 0.75rem;
  }
  .nav-link {
    font-size: 1.5rem;
    &:focus {
      background-color: rgba($secondary, .1);
    }
  }
}

/* Nav Container */
.right-nav-container {
  position: relative;
  z-index: 100;
  background-color: $white;
  min-height: 100vh;
  border-left: 1px solid rgba($border-color, 1);
  .nav-right-sidebar {
    padding-top: 120px;
  }
}

/* Right Sidebar Tab Content */
.right-sidebar-tab {
  position: fixed;
  top: 76px;
  right: -375px;
  width: 315px;
  min-height: 100%;
  background-color: $white;
  border-left: 1px solid $border-color;
  .tab-content {
    position: relative;
    z-index: 10;
  }

}

/* Right Sidebar Content Out */
.right-sidebar-out {
  .content-wrapper {
    @include media-breakpoint-up(xl) {
      margin-right: 60px;
      transition: margin-right .3s ease-in-out;
    }
  }
  .right-sidebar-tab {
    right: -315px;
    transition: right .3s ease-in-out;
  }
}

/* Right Sidebar Content IN */
.right-sidebar-in {
  .content-wrapper {
    @include media-breakpoint-up(xl) {
      margin-right: 375px;
      transition: margin-right .3s ease-in-out;
    }

  }
  .right-sidebar-tab {
    right: 60px;
    transition: right .3s ease-in-out;
  }

}


/* Nav Icon Small */
.icon-sm {
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 50%;
  padding: 0;
  text-align: center;
}


/* Card Right Sidebar */
.card-right-sidebar {
  border: none;
  .card-header {
    background-color: transparent;
    padding: 1rem;
    padding-top: 1.5rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;

    color: $dark;
    .close {
      width: 30px;
      height: 30px;
      line-height: 1;
      border-radius: 50%;
      margin-top: -5px;
      &:hover {
        background-color: rgba($secondary, .1);
      }
    }
    .card-title {
      margin-bottom: 0;
      font-size: 0.94rem;
    }
  }
}

// /*====== Sidebar Color Variation =======*/

/* Sidebar Light */
.left-sidebar {
  &.sidebar-light {
    background-color: $white;
    border-right: 1px solid $border-color;
    .app-brand {
      border-bottom: 1px solid $border-color;
      .brand-name {
        color: $dark;
      }
    }
    .sidebar .sub-menu > li > a {
      color: darken($text-color, 15%);
    }
    .sidebar .sidebar-inner > li > a {
      color: darken($text-color, 15%);
    }
    .sidebar .sidebar-inner > li.active > a {
      background-color: $primary;
      color: $white;
    }
    .sidebar .sidebar-inner .section-title {
      color: darken($text-color, 15%);
    }
    .sidebar .sub-menu .has-sub.active .sidenav-item-link {
      color: $black-russian;
    }
    .sidebar .sub-menu .sub-menu .active a {
      color: $black-russian;
    }
    .sidebar .sub-menu .sub-menu .active a::after {
      border-color: $black-russian;
    }
    .sidebar .sub-menu > li .sub-menu > li > a::after {
      border-color: darken($text-color, 15%);
    }
    .sidebar .sub-menu > .active .sidenav-item-link {
      color: $black-russian;
    }
    .sidebar-footer {
      background-color: $white;
    }

    &::before {
      background-color: rgba($white, .92);
    }
  }
}

/* Sidebar Light */
.left-sidebar {
  &.sidebar-primary {
    background-color: $primary;
    border-right: 1px solid $border-color;
    .app-brand {
      border-bottom: 1px solid $border-color;
      .brand-name {
        color: $dark;
      }
    }
    .sidebar .sub-menu > li > a {
      color: darken($text-color, 15%);
    }
    .sidebar .sidebar-inner > li > a {
      color: darken($text-color, 15%);
    }
    .sidebar .sidebar-inner > li.active > a {
      background-color: $white;
      color: $white;
    }
    .sidebar .sidebar-inner .section-title {
      color: darken($text-color, 15%);
    }
    .sidebar .sub-menu .has-sub.active .sidenav-item-link {
      color: $black-russian;
    }
    .sidebar .sub-menu .sub-menu .active a {
      color: $black-russian;
    }
    .sidebar .sub-menu .sub-menu .active a::after {
      border-color: $black-russian;
    }
    .sidebar .sub-menu > li .sub-menu > li > a::after {
      border-color: darken($text-color, 15%);
    }
    .sidebar .sub-menu > .active .sidenav-item-link {
      color: $black-russian;
    }
    .sidebar-footer {
      background-color: $white;
    }

    &::before {
      background-color: rgba($white, .92);
    }
  }
}

/* Right Sidebar Dark */
.right-sidebar-dark {
  .right-nav-container {
    background-color: $dark;
    border-left: 1px solid rgba($white, 0.2);
  }
}

